<template>
  <div class="relative sticky top-0 z-998 transition-all" :bg="theme === 'white' ? 'transparent' : 'white'" h="12" px="4" flex="~ items-center justify-between" flex-shrink="0">
    <div flex="~ items-center" font="300" text="xs white">
      <NuxtIcon v-if="showBack" name="my-icon:navback" :text="theme === 'white' ? 'white' : '#121212'" class="mr-2 align-middle text-xl" @click="goBack" />
      <template v-if="isPartner">
        <NuxtLink :to="cobrand.logoLink || { path: `/${locale}`, query: getCommonQuery() }" :external="!!cobrand.logoLink" target="_blank" rel="noopener noreferrer">
          <img v-if="cobrand.logo" :src="cobrand.logo?.replace(/^http:\/\//i, 'https://')" class="max-w-25 object-contain align-middle" h="6" />
          <span v-else-if="cobrand.name" class="text-white md:ml-2 md:text-lg">{{ cobrand.name }}</span>
        </NuxtLink>
        <template v-if="commonDataStore.commonData.partnerInfo?.showPoweredText">
          <span mx="1.5 md:4" w="1px" :bg="theme === 'white' ? 'white' : '#121212'" h="4 md:6"></span>
          <span class="md:text-sm" mr="1 md:2" :text="theme === 'white' ? 'white' : '#121212'">{{ t('key.business.user.header.poweredBy') }}</span>
        </template>
      </template>
      <NuxtLink :to="{ path: `/${locale}`, query: getCommonQuery() }">
        <img v-if="!isPartner" w="20 md:30" self="end md:center" class="mt--1" :src="theme === 'white' ? logoWhite : logoGreen" />
        <img v-else-if="commonDataStore.commonData.partnerInfo?.showPoweredText" w="14 md:20" self="end md:center" :src="theme === 'white' ? logoWhite : logoGreen" />
      </NuxtLink>
    </div>
    <div flex="~ items-center" space-x="4">
      <NuxtIcon name="attr-icon:currency" class="text-2xl" :class="[theme === 'white' && 'text-white']" @click="showCurrencyPopup = true" />
      <NuxtIcon name="attr-icon:language" class="text-2xl" :class="[theme === 'white' && 'text-white']" @click="showLocalePopup = true" />
    </div>
  </div>

  <!-- 货币选择弹框 -->
  <BaseAZPopup v-model:show="showCurrencyPopup" :title="t('key.business.user.header.selectCurrency')">
    <div class="flex flex-col" pb="8">
      <div
        v-for="(item, index) in currencyInfo"
        :key="item.currency"
        py="4" flex="~ items-center justify-between"
        :class="[index !== currencyInfo.length - 1 && 'van-hairline--bottom']" @click="currentCurrency = item.currency || ''"
      >
        <div :style="{ color: currentCurrency === item.currency ? 'var(--tc-color-primary)' : '#121212' }">
          <span text="sm" font="500" mr="4">{{ item.currency }}</span>
          <span text="sm" font="300">{{ item.fullName }}</span>
        </div>
        <NuxtIcon v-if="currentCurrency === item.currency" name="my-icon:mark" class="text-xl" style="color: var(--tc-color-primary);" />
      </div>
    </div>
  </BaseAZPopup>

  <!-- 语言选择弹框 -->
  <BaseAZPopup v-model:show="showLocalePopup" :title="t('key.lang')">
    <div class="flex flex-col" pb="8">
      <div
        v-for="(item, index) in locales || []"
        :key="item.locale"
        py="4" flex="~ items-center justify-between"
        :class="[index !== locales.length - 1 && 'van-hairline--bottom']" @click="currentLocale = item;"
      >
        <span :style="{ color: currentLocale === item ? 'var(--tc-color-primary)' : '#121212' }" text="sm" font="300">{{ langPCMap[item as string] }}</span>
        <NuxtIcon v-if="currentLocale === item" name="my-icon:mark" class="text-xl" style="color: var(--tc-color-primary);" />
      </div>
    </div>
  </BaseAZPopup>
</template>

<script lang="ts" setup>
import { langPCMap } from '~/constant/languages'
import { getCommonQuery } from '~/utils'
import logoWhite from '@/assets/icons/azgoxplore.png'
import logoGreen from '@/assets/icons/azgoxplore-green.png'

const { theme } = defineProps({
  theme: {
    type: String as PropType<'green' | 'white'>,
    default: 'green',
  },
  showBack: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const router = useRouter()
const { locale } = useLocale()
const commonDataStore = useCommonStore()

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}

  return {
    logo: partnerInfo?.distLogo,
    name: partnerInfo?.distName,
    logoLink: partnerInfo?.distLogoSkipUrl,
  }
})

const isPartner = computed(() => Boolean(route.query.campaign_id) && (cobrand.value.logo || cobrand.value.name))

function goBack() {
  router.go(-1)
}

const { t } = useI18n()
const { showCurrencyPopup, currencyInfo, currentCurrency, showLocalePopup, locales, currentLocale } = useNavbar()
</script>

<style>

</style>
